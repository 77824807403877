table {
  text-align: left;
  font-size: 0.5em;
}

th,
td {
  padding: 5px;
}

.description {
  border: solid 3px gray;
  border-radius: 10px;
  padding: 1em;
  font-size: 0.5em;
  font-weight: bold;
  width: 50%;
  text-align: left;
}

.message {
  padding: 1em;
  font-weight: bold;
  max-width: 90vw;
}
